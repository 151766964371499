.App {
    display: flex;
    justify-content: center;
    padding: 20px;
    font-family: -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
}

html {
    background-color: rgb(38, 38, 44);
    color: white;
}


a {
    color: rgb(101, 150, 255);
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.App-section {
    padding-top: 5px;
}

.App-title {
    font-size: 30px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-section {
    display: flex;
    flex-direction: column;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content {
    width: 55rem;
    max-width: 100%;
}
